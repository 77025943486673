import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import './HealthList.css'
import malepic from '../../../../Images/human-body-frontal-removebg-preview.png'
import femalePic from '../../../../Images/Group 237928.png'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Vitalhealth from '../HealthcardVitals/VitalHealth';
import BasicScreenVital from '../HealthcardVitals/BasicScrenVital';
import BmiHealth from '../HealthcardVitals/BmiHealth';
import ImmunisationHealth from '../HealthcardVitals/ImmunisationHealth';
import AuditoryHealth from '../HealthcardVitals/AuditoryHealth';
import DentalHealth from '../HealthcardVitals/DentalHealth';
import VisionHealth from '../HealthcardVitals/VisionHealth';
import PsychologicalHealth from '../HealthcardVitals/PsychologicalHealth';
import axios from 'axios'
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import TablePagination from '@mui/material/TablePagination';
import Healthcarddownload from '../Healthcarddownload';
import backgroundImage from '../../../../Images/Group 427318866 (2).png';
import secondImage from '../../../../Images/Group 427318867.png';
import CircularProgress from '@mui/material/CircularProgress';
import defaultImage from '../../../../Images/Default Image.webp'
import SignLogo from '../../../../Images/ImportedPhoto_1716285997519.jpg'

const HealthList = () => {

    const Port = process.env.REACT_APP_API_KEY;
    const [openForm, setOpenForm] = useState('bmiform')
    const [loading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Set to 10 for 10 entries per page
    const accessToken = localStorage.getItem('token');

    //// access the source from local storage
    const SourceUrlId = localStorage.getItem('loginSource');

    //// access the source name from local storage
    const SourceNameUrlId = localStorage.getItem('SourceNameFetched');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    //////////////////////////// Nav Dropdown //////////////////////////
    const [sourceNav, setSourceNav] = useState([]); // State for source options
    const [selectedSource, setSelectedSource] = useState(''); // State to store selected source

    const [sourceStateNav, setSourceStateNav] = useState([]); // State for source state options
    const [selectedStateNav, setSelectedStateNav] = useState('');

    const [sourceDistrictNav, setSourceDistrictNav] = useState([]); // State for source district options
    const [selectedDistrictNav, setSelectedDistrictNav] = useState('')

    const [sourceTehsilNav, setSourceTehsilNav] = useState([]); // District for source Tehsil options
    const [selectedTehsilNav, setSelectedTehsilNav] = useState('')

    const [sourceName, setSourceName] = useState([]);
    const [selectedName, setSelectedName] = useState('');

    console.log(selectedSource, selectedStateNav, selectedDistrictNav, selectedTehsilNav, selectedName);
    ///////////// scheudle API 
    const [scheduleData, setScheduleData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [store, setStore] = useState([]) ///////////////Schdule citizen info
    const [newId, setNewID] = useState("") ///// citizen id
    const [psychoData, setPsychoData] = useState(null); // Initialize with an appropriate default value
    const [dentalData, setDentalData] = useState(null); // Initialize with an appropriate default value
    const [visionData, setVisionData] = useState(null); // Initialize with an appropriate default value
    const [auditoryData, setAuditoryData] = useState(null); // Initialize with an appropriate default value
    const [vitalsData, setVitalsData] = useState(null); // Initialize with an appropriate default value
    const [bmiData, setBmiData] = useState(null); // Initialize with an appropriate default value
    const [immunizationData, setImmunizationData] = useState(null); // Initialize with an appropriate default value
    const [selectedCitizenId, setSelectedCitizenId] = useState(null);
    const [basicScreenData, setBasicScreenData] = useState(null)
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [selectedForm, setSelectedForm] = useState('');

    const handleFormClick = (formType) => {
        setSelectedForm(formType);
        setOpenForm(formType);
    };

    const handlePointClick = (form) => {
        setOpenForm(form);
    };

    ////////////////////////////// navbar value dropdown get ///////////////////////////////
    ///// source Dropdown
    useEffect(() => {
        fetch(`${Port}/Screening/Source_Get/?source_pk_id=${SourceUrlId}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setSourceNav(data);
            })
            .catch(error => {
                console.error('Error fetching sources:', error);
            });
    }, []);

    //// Soure State against selected source
    useEffect(() => {
        const fetchStateNavOptions = async () => {
            if (selectedSource) {
                try {
                    const res = await fetch(`${Port}/Screening/source_and_pass_state_Get/${selectedSource}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                    const data = await res.json();
                    setSourceStateNav(data);
                } catch (error) {
                    console.error("Error fetching state against source data:", error);
                }
            }
        };
        fetchStateNavOptions();
    }, [selectedSource]);

    //// Soure District against selected source state/////////
    useEffect(() => {
        const fetchDistrictNavOptions = async () => {
            if (selectedStateNav) {
                try {
                    const res = await fetch(`${Port}/Screening/state_and_pass_district_Get/${selectedSource}/${selectedStateNav}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                    const data = await res.json();
                    setSourceDistrictNav(data);
                } catch (error) {
                    console.error("Error fetching districts against state data:", error);
                }
            }
        };
        fetchDistrictNavOptions();
    }, [selectedStateNav]);

    //// Soure Tehsil against selected source District/////////
    useEffect(() => {
        const fetchTehsilNavOptions = async () => {
            if (selectedDistrictNav) {
                try {
                    const res = await fetch(`${Port}/Screening/district_and_pass_taluka_Get/${selectedSource}/${selectedDistrictNav}/`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                    const data = await res.json();
                    setSourceTehsilNav(data);
                } catch (error) {
                    console.error("Error fetching Tehsil against District data:", error);
                }
            }
        };
        fetchTehsilNavOptions();
    }, [selectedDistrictNav]);

    //// Soure Name against selected source district
    useEffect(() => {
        const fetchSourceNameOptions = async () => {
            if (selectedSource && selectedStateNav && selectedDistrictNav && selectedTehsilNav) {
                try {
                    const res = await fetch(`${Port}/Screening/taluka_and_pass_SourceName_Get/?SNid=${selectedTehsilNav}&So=${selectedSource}&source_pk_id=${SourceNameUrlId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                    const data = await res.json();
                    setSourceName(data);
                } catch (error) {
                    console.error("Error fetching Source Name against Tehsil data:", error);
                }
            }
        };
        fetchSourceNameOptions();
    }, [selectedSource, selectedStateNav, selectedDistrictNav, selectedTehsilNav]);

    ////////////// search API
    const handlesubmit = async (e) => {
        let url = `${Port}/Screening/healthcards/?`;

        if (SourceUrlId) {
            url += `source_id_id=${SourceUrlId}&`;
        }

        if (SourceNameUrlId) {
            url += `source_name_id=${SourceNameUrlId}&`;
        }

        if (selectedSource) {
            url += `source=${selectedSource}&`;

            if (selectedStateNav) {
                url += `state_id =${selectedStateNav}&`;

                if (selectedDistrictNav) {
                    url += `district_id=${selectedDistrictNav}&`;

                    if (selectedTehsilNav) {
                        url += `tehsil_id=${selectedTehsilNav}&`;

                        if (selectedName) {
                            url += `source_name=${selectedName}&`;
                        }
                    }
                }
            }
        }

        setLoading(true);
        try {
            const response = await axios.get(url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            setSearchResults(response.data);
            console.log(response.data);
        } catch (error) {
            console.log('Error while fetching data', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handlesubmit();
    }, [])

    //////////// download 
    function getCurrentTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }
    const [selectedScheduleCount, setSelectedScheduleCount] = useState(null);

    const [scheduleId, setScheduleId] = useState(null);
    const [image, setImage] = useState('')
    console.log(image, 'image fetched state');
    const [imageShows, setImageShows] = useState('')
    console.log(imageShows, 'imag downloading.....');

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const apiUrl = `${Port}${image}`;
                console.log("API URL:", apiUrl);

                const res = await fetch(apiUrl);
                console.log("API Response:", res);

                if (res.ok) {
                    const blob = await res.blob();
                    const blobUrl = URL.createObjectURL(blob);
                    setImageShows(blobUrl);
                } else {
                    throw new Error(`Error fetching image. Status: ${res.status}`);
                }
            } catch (error) {
                console.error("Error fetching image:", error);
                // Handle error setting state if needed
            }
        };

        fetchImageData();
    }, [image]);

    function splitTextIntoLines(pdf, text, maxWidth) {
        const lines = [];
        let currentLine = '';
        const words = text.split(' ');

        words.forEach((word, index) => {
            // Add word to the current line
            const testLine = currentLine + ' ' + word;
            const width = pdf.getStringUnitWidth(testLine) * pdf.internal.getFontSize();

            // Check if adding the word exceeds the maximum width
            if (width <= maxWidth || index === 0) {
                currentLine = testLine.trim();
            } else {
                // Push the current line to the array
                lines.push(currentLine.trim());
                currentLine = word;
            }
        });

        // Push the remaining line
        lines.push(currentLine.trim());

        return lines;
    }

    const handleDownloadClick = async () => {
        if (!scheduleData || !selectedScheduleCount) {
            console.error('No data available for download or no schedule count selected');
            return;
        }

        try {
            const response = await fetch(`${Port}/Screening/citizen-download/${selectedCitizenId}/${selectedScheduleCount}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            console.log(response);

            const additionalData = await response.json();
            const auditInfo = additionalData.audit_info?.[0];
            const bmiInfo = additionalData.bmi_info?.[0]; // Assuming bmi_info is present in the response
            const basicInfo = additionalData.basic_info?.[0]; // Assuming basic_info is present in the response
            const vitalInfo = additionalData.vital_info?.[0]; // Assuming basic_info is present in the response
            const psychoInfo = additionalData.psycho_info?.[0]; // Assuming psycho_info is present in the response
            const visionInfo = additionalData.vision_info?.[0]; // Assuming psycho_info is present in the response
            const immunisationInfo = additionalData.immunization_info?.[0]; // Assuming psycho_info is present in the response
            const dentalInfo = additionalData.dental_info?.[0]; // Assuming psycho_info is present in the response

            // Set font family and size
            const pdf = new jsPDF();
            const fontSettings = {
                font: 'Poppins',
                fontSize: 12,
            };
            const imgWidth = 210; // Adjust as needed
            const imgHeight = 297; // Adjust as needed

            pdf.addImage(backgroundImage, 'PNG', 0, 0, imgWidth, imgHeight);

            // Header
            const leftMargin = 27; // Adjust the left margin as needed

            const headerText = `${basicInfo.source_name}`;
            const headerX = imgWidth / 1.7 + leftMargin;
            const headerY = 15;

            // Check if citizenInfo is available before attempting to use it
            if (imageShows) {
                const cmToPoints = 15; // Conversion factor from cm to points

                const imageWidthCm = 1.55; // Set the width of the image in cm
                const imageHeightCm = 1.7; // Set the height of the image in cm
                const borderRadiusCm = 1.5; // Set the border radius of the image in cm

                const imageWidth = imageWidthCm * cmToPoints; // Convert width from cm to points
                const imageHeight = imageHeightCm * cmToPoints; // Convert height from cm to points
                const borderRadius = borderRadiusCm * cmToPoints; // Convert border radius from cm to points

                pdf.addImage(imageShows, 'JPEG', 20, 15, imageWidth, imageHeight, borderRadius); // Adjust the coordinates, dimensions, and border radius as needed
            } else {
                // Show the default image if imageShows is not available
                const cmToPoints = 15; // Conversion factor from cm to points

                const defaultImageWidthCm = 1.5; // Set the width of the default image in cm
                const defaultImageHeightCm = 1.5; // Set the height of the default image in cm

                const defaultImageWidth = defaultImageWidthCm * cmToPoints; // Convert width from cm to points
                const defaultImageHeight = defaultImageHeightCm * cmToPoints; // Convert height from cm to points

                pdf.addImage(defaultImage, 'WEBP', 20, 19, defaultImageWidth, defaultImageHeight); // Adjust the coordinates and dimensions as needed
            }

            if (basicInfo) {
                const citizenInfoFontSize = 10;

                // Set text color to white
                pdf.setTextColor(255, 255, 255);

                // Display Citizen Name
                const nameText = `${basicInfo.name}`;
                pdf.text(nameText, 45, 28);
                pdf.setFontSize(citizenInfoFontSize);

                // Display Gender
                const genderText = `${basicInfo.gender}`;
                pdf.text(genderText, 45, 34);
                pdf.setFontSize(citizenInfoFontSize);

                // Display Age
                const ageText = ` ${basicInfo.year} years`;
                pdf.text(ageText, 43.5, 39);
                pdf.setFontSize(citizenInfoFontSize);

                // Set text color back to black (or any other desired color)
                pdf.setTextColor(0, 0, 0);

                // Apply bottom margin for all displayed text
                const bottomMargin = 10;
                pdf.text('', 2, pdf.internal.pageSize.height - bottomMargin);

                // Display citizen_id
                if (SourceUrlId === '1') {

                    pdf.setTextColor(255, 255, 255); // Set text color to white
                    const citizenIdText = `Citizen ID: ${basicInfo.citizen_id}`;
                    pdf.text(citizenIdText, imgWidth - 20, 34, { align: 'right' });
                    pdf.setFontSize(citizenInfoFontSize);

                    // Display Schedule Count
                    const scheduleCountText = `Schedule ID : ${basicInfo.schedule_id}`;
                    pdf.text(scheduleCountText, imgWidth - 20, 28, { align: 'right' });
                    pdf.setFontSize(citizenInfoFontSize);

                    // Display Schedule Count
                    const shceduleID = `Screening : ${basicInfo.schedule_count}`;
                    pdf.text(shceduleID, imgWidth - 20, 21, { align: 'right' });
                    pdf.setFontSize(citizenInfoFontSize);
                }

                else {
                    // Display Schedule Count
                    const scheduleCountText = `EMP ID: ${basicInfo.employee_id}`;
                    pdf.setTextColor(255); // Set text color to white
                    pdf.text(scheduleCountText, imgWidth - 20, 28, { align: 'right' });
                    pdf.setFontSize(citizenInfoFontSize);
                }

                // const currentTime = getCurrentTime();

                // Display current time
                // pdf.setTextColor(0, 0, 0); // Set text color back to black
                // const downloadTimeText = `Download Time: ${currentTime}`;
                // pdf.text(downloadTimeText, 20, pdf.internal.pageSize.height - 2);
            }

            pdf.setTextColor(255, 255, 255); // Set text color to white for the header
            pdf.setFontSize(25); // Adjust the font size directly

            pdf.text(headerText, headerX, headerY, { align: 'right' });

            // Reset font size and text color for subsequent text
            pdf.setFont(fontSettings.font, 'normal');
            pdf.setFontSize(fontSettings.fontSize);
            pdf.setTextColor(0, 0, 0); // Reset text color to black


            const cardWidth = 85;
            const bmiCardHeight = 70; // Adjust the BMI card height as needed
            const auditoryCardHeight = 155; // Adjust the Auditory card height as needed
            const borderRadius = 4; // Border radius in millimeters

            // First Card (BMI Information)
            const cardX1 = 10;
            const marginTopBMI = 33; // Adjust the margin-top for BMI card as needed
            const cardY1 = 20 + marginTopBMI;

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(cardX1, cardY1, cardWidth, bmiCardHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('GROWTH MONITORING', cardX1 + 5, cardY1 + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            pdf.text('Height:', cardX1 + 5, cardY1 + 15); // Label without bold
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`${bmiInfo?.height || 'N/A'}`, cardX1 + 50, cardY1 + 15); // Bold text
            pdf.setFont(fontSettings.font, 'normal'); // Reset font style to normal for subsequent text

            // Weight
            pdf.text('Weight:', cardX1 + 5, cardY1 + 22);
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`${bmiInfo?.weight || 'N/A'}`, cardX1 + 50, cardY1 + 22);
            pdf.setFont(fontSettings.font, 'normal');

            // Weight for Age
            pdf.text('Weight for Age:', cardX1 + 5, cardY1 + 29);
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`${bmiInfo?.weight_for_age || 'N/A'}`, cardX1 + 50, cardY1 + 29);
            pdf.setFont(fontSettings.font, 'normal');

            // Height for Age
            pdf.text('Height for Age:', cardX1 + 5, cardY1 + 36);
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`${bmiInfo?.height_for_age || 'N/A'}`, cardX1 + 50, cardY1 + 36);
            pdf.setFont(fontSettings.font, 'normal');

            // Weight for Height
            pdf.text('Weight for Height:', cardX1 + 5, cardY1 + 43);
            const weightForHeightValue = bmiInfo?.weight_for_height || 'N/A';

            // Check if there is a possible risk of overweight
            const isPossibleRiskOfOverweight = []

            // Adjust font size based on the condition
            if (isPossibleRiskOfOverweight) {
                pdf.setFontSize(12);
                pdf.setFont(fontSettings.font, 'bold');
            }

            // Split text into lines if there is a possible risk
            const lines = pdf.splitTextToSize(weightForHeightValue, cardWidth - 55);

            // Iterate through lines and add line breaks
            lines.forEach((line, index) => {
                pdf.text(line, cardX1 + 50, cardY1 + 43 + (index * 5)); // Adjust Y position based on your layout
            });

            // Reset font size to normal
            pdf.setFontSize(12);
            pdf.setFont(fontSettings.font, 'normal');

            // BMI
            pdf.text('BMI:', cardX1 + 5, cardY1 + 54);
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`${bmiInfo?.bmi || 'N/A'}`, cardX1 + 50, cardY1 + 54);
            pdf.setFont(fontSettings.font, 'normal');

            // Arm Size
            if (SourceUrlId === '1') {
                pdf.text('Arm Size:', cardX1 + 5, cardY1 + 61);
                pdf.setFont(fontSettings.font, 'bold');
                pdf.text(`${bmiInfo?.arm_size || 'N/A'}`, cardX1 + 50, cardY1 + 61);
                pdf.setFont(fontSettings.font, 'normal');
            }

            // Second Card (Basic Information)
            const cardX2 = cardX1 + cardWidth + 10;
            const cardY2 = cardY1;

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(cardX2, cardY2, cardWidth, auditoryCardHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('GENERAL EXAMINATION', cardX2 + 5, cardY2 + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            const labelX = cardX2 + 5;
            const valueX = cardX2 + 50;

            // head
            pdf.text(`Head:`, labelX, cardY2 + 20);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.head || 'N/A'}`, valueX, cardY2 + 20);
            pdf.setFont(fontSettings.font, 'normal');

            // Neck
            pdf.text(`Neck:`, labelX, cardY2 + 27);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.neck || 'N/A'}`, valueX, cardY2 + 27);
            pdf.setFont(fontSettings.font, 'normal');

            // Skin Texture
            pdf.text(`Skin Texture :`, labelX, cardY2 + 34);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.skin_texture || 'N/A'}`, valueX, cardY2 + 34);
            pdf.setFont(fontSettings.font, 'normal');

            // Lips
            pdf.text(`Mouth Lips:`, labelX, cardY2 + 41);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.lips || 'N/A'}`, valueX, cardY2 + 41);
            pdf.setFont(fontSettings.font, 'normal');

            // Toungue
            pdf.text(`Mouth Tongue:`, labelX, cardY2 + 48);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.tongue || 'N/A'}`, valueX, cardY2 + 48);
            pdf.setFont(fontSettings.font, 'normal');

            // Hair Density
            pdf.text(`Hair Density:`, labelX, cardY2 + 55);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.hair_density || 'N/A'}`, valueX, cardY2 + 55);
            pdf.setFont(fontSettings.font, 'normal');

            // Alopecia
            pdf.text(`Alopecia:`, labelX, cardY2 + 62);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.alopecia || 'N/A'}`, valueX, cardY2 + 62);
            pdf.setFont(fontSettings.font, 'normal');

            // Abdomen
            pdf.text(`Abdomen:`, labelX, cardY2 + 69);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.abdomen || 'N/A'}`, valueX, cardY2 + 69);
            pdf.setFont(fontSettings.font, 'normal');

            // Skin colour
            pdf.text(`Skin colour:`, labelX, cardY2 + 76);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.skin_color || 'N/A'}`, valueX, cardY2 + 76);
            pdf.setFont(fontSettings.font, 'normal');

            // skin_lesions
            pdf.text(`Skin Lesions:`, labelX, cardY2 + 83);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.skin_lesions || 'N/A'}`, valueX, cardY2 + 83);
            pdf.setFont(fontSettings.font, 'normal');

            // Hair Colour
            pdf.text(`Hair Colour:`, labelX, cardY2 + 90);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.hair_color || 'N/A'}`, valueX, cardY2 + 90);
            pdf.setFont(fontSettings.font, 'normal');

            // Nose
            pdf.text(`Nose:`, labelX, cardY2 + 97);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.nose || 'N/A'}`, valueX, cardY2 + 97);
            pdf.setFont(fontSettings.font, 'normal');

            // GUMS
            pdf.text(`Gums:`, labelX, cardY2 + 104);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.gum || 'N/A'}`, valueX, cardY2 + 104);
            pdf.setFont(fontSettings.font, 'normal');

            // dention
            pdf.text(`Dention:`, labelX, cardY2 + 111);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.dention || 'N/A'}`, valueX, cardY2 + 111);
            pdf.setFont(fontSettings.font, 'normal');

            // oral_mucosa
            pdf.text(`Oral Mucosa:`, labelX, cardY2 + 118);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.oral_mucosa || 'N/A'}`, valueX, cardY2 + 118);
            pdf.setFont(fontSettings.font, 'normal');

            // extremity
            pdf.text(`Extremity:`, labelX, cardY2 + 125);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${basicInfo?.extremity || 'N/A'}`, valueX, cardY2 + 125);
            pdf.setFont(fontSettings.font, 'normal');

            /////////////////////////////////////////////// Third Card (Vitals)
            const cardX3 = cardX1;
            const marginTopBasicInfo = -80;
            const cardY3 = cardY1 + auditoryCardHeight + marginTopBasicInfo;
            const basicInfoCardHeight = 80;

            pdf.setFillColor(244, 245, 250);
            pdf.roundedRect(cardX3, cardY3, cardWidth, basicInfoCardHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('VITALS', cardX3 + 5, cardY3 + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            const basicLabelX = cardX3 + 5;
            const basicValueX = cardX3 + 50;

            // Pulse
            pdf.text(`Pulse:`, basicLabelX, cardY3 + 20);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.pulse || 'N/A'}`, basicValueX, cardY3 + 20);
            pdf.setFont(fontSettings.font, 'normal');

            // SYS
            pdf.text(`Sys(mm):`, basicLabelX, cardY3 + 27);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.sys_mm || 'N/A'}`, basicValueX, cardY3 + 27);
            pdf.setFont(fontSettings.font, 'normal');

            // dys
            pdf.text(`Dys(mm):`, basicLabelX, cardY3 + 34);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.dys_mm || 'N/A'}`, basicValueX, cardY3 + 34);
            pdf.setFont(fontSettings.font, 'normal');

            // O2
            pdf.text(`O2:`, basicLabelX, cardY3 + 41);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.oxygen_saturation || 'N/A'}`, basicValueX, cardY3 + 41);
            pdf.setFont(fontSettings.font, 'normal');

            // RR
            pdf.text(`RR :`, basicLabelX, cardY3 + 48);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.rr || 'N/A'}`, basicValueX, cardY3 + 48);
            pdf.setFont(fontSettings.font, 'normal');

            // temp
            pdf.text(`Temperature :`, basicLabelX, cardY3 + 55);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${vitalInfo?.temp || 'N/A'}`, basicValueX, cardY3 + 55);
            pdf.setFont(fontSettings.font, 'normal');

            // HB
            if (SourceUrlId === '1') {
                pdf.text(`HB:`, basicLabelX, cardY3 + 62);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${vitalInfo?.hb || 'N/A'}`, basicValueX, cardY3 + 62);
                pdf.setFont(fontSettings.font, 'normal');
            }

            /////////////////////// Fourth Card (Audit Information)
            const marginRightCard4 = -180; // Adjust the margin-right for the fourth card as needed
            const marginTopCard4 = 160; // Adjust the margin-top for the fourth card as needed
            const cardX4 = cardX2 + cardWidth + marginRightCard4;
            const cardY4 = cardY2 + marginTopCard4;
            const psychoCardHeightt = 70; // Adjust the height as needed

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(cardX4, cardY4, cardWidth, psychoCardHeightt, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('AUDIOMETRY', cardX4 + 5, cardY4 + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            const auditLabelX = cardX4 + 5;
            const auditValueX = cardX4 + 50;

            pdf.text(`Right:`, auditLabelX, cardY4 + 20);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${auditInfo?.right || 'Normal'}`, auditValueX, cardY4 + 20);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Left:`, auditLabelX, cardY4 + 27);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${auditInfo?.left || 'Normal'}`, auditValueX, cardY4 + 27);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Observation:`, auditLabelX, cardY4 + 34);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${auditInfo?.tratement_given || 'NAD'}`, auditValueX, cardY4 + 34);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Remark:`, auditLabelX, cardY4 + 41);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${auditInfo?.remark || 'Normal'}`, auditValueX, cardY4 + 41);
            pdf.setFont(fontSettings.font, 'normal');

            //////////////////////////////////// Fourth Card (Psychological Information)
            const cardX5 = cardX4 + cardWidth + 10;
            const cardY5 = cardY4;
            const cardSeparateHeight = 70; // Set a separate height for the card, change this value as needed

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(cardX5, cardY5, cardWidth, cardSeparateHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('VISION', cardX5 + 5, cardY5 + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            const psychoLabelX = cardX5 + 5;
            const psychoValueX = cardX5 + 50;

            // Include specific fields for the new card (replace with your actual fields)
            // pdf.text(`Eye:`, psychoLabelX, cardY5 + 20);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${visionInfo?.eye || 'N/A'}`, psychoValueX, cardY5 + 20);
            // pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Vision with Glasses:`, psychoLabelX, cardY5 + 27);
            // pdf.setFont('Roboto', 'vision_with_glasses');
            // pdf.text(`${visionInfo?.vision_without_glasses === 1 ? 'Good' : visionInfo?.vision_without_glasses === '' ? 'N/A' : 'Poor'}`, psychoValueX, cardY5 + 27);
            // pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Vision without glasses:`, psychoLabelX, cardY5 + 34);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${visionInfo?.vision_without_glasses === 1 ? 'Good' : visionInfo?.vision_without_glasses === '' ? 'N/A' : 'Poor'}`, psychoValueX, cardY5 + 34);
            // pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Eye Muscles Control:`, psychoLabelX, cardY5 + 41);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${visionInfo?.eye_muscle_control || 'N/A'}`, psychoValueX, cardY5 + 41);
            // pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Refractive Error:`, psychoLabelX, cardY5 + 48);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${visionInfo?.refractive_error !== 1 ? 'No' : 'Yes' || 'N/A'}`, psychoValueX, cardY5 + 48);
            // pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Visual Perimetry:`, psychoLabelX, cardY5 + 55);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${visionInfo?.visual_perimetry || 'N/A'}`, psychoValueX, cardY5 + 55);
            // pdf.setFont(fontSettings.font, 'normal');

            if (SourceUrlId === '1') {
                pdf.text(`Eye:`, psychoLabelX, cardY5 + 20);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${visionInfo?.eye || 'N/A'}`, psychoValueX, cardY5 + 20);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Vision with Glasses:`, psychoLabelX, cardY5 + 27);
                pdf.setFont('Roboto', 'vision_with_glasses');
                pdf.text(`${visionInfo?.vision_with_glasses === "1" ? 'Good' : visionInfo?.vision_with_glasses === '' ? 'N/A' : 'Poor'}`, psychoValueX, cardY5 + 27);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Vision without glasses:`, psychoLabelX, cardY5 + 34);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${visionInfo?.vision_without_glasses === "1" ? 'Good' : visionInfo?.vision_without_glasses === '' ? 'N/A' : 'Poor'}`, psychoValueX, cardY5 + 34);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Eye Muscles Control:`, psychoLabelX, cardY5 + 41);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${visionInfo?.eye_muscle_control || 'N/A'}`, psychoValueX, cardY5 + 41);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Refractive Error:`, psychoLabelX, cardY5 + 48);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${visionInfo?.refractive_error !== "1" ? 'No' : 'Yes' || 'N/A'}`, psychoValueX, cardY5 + 48);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Visual Perimetry:`, psychoLabelX, cardY5 + 55);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${visionInfo?.visual_perimetry || 'N/A'}`, psychoValueX, cardY5 + 55);
                pdf.setFont(fontSettings.font, 'normal');
            }
            else {
                const psychoLabelX = cardX5 + 5;
                const psychoValueX = cardX5 + 25;

                // Bold "Vision With Glass"
                pdf.setFont('Roboto', 'bold');
                pdf.text(`Vision With Glass:`, psychoLabelX, cardY5 + 20);

                // N Right
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`N Right:`, psychoLabelX, cardY5 + 27);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.re_near_with_glasses || 'N/A'}`, psychoValueX, cardY5 + 27); // Adjust X-coordinate for the value

                // N Left
                const leftLabelX = psychoLabelX + 40; // Adjust this value to position "N Left" correctly
                const leftValueX = leftLabelX + 15; // Adjust this value to position the left value correctly

                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`N Left:`, leftLabelX, cardY5 + 27);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.le_near_with_glasses || 'N/A'}`, leftValueX, cardY5 + 27);

                // F Right
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`F Right:`, psychoLabelX, cardY5 + 34);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.re_far_with_glasses || 'N/A'}`, psychoValueX, cardY5 + 34); // Adjust X-coordinate for the value

                // F Left
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`F Left:`, leftLabelX, cardY5 + 34);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.le_far_with_glasses || 'N/A'}`, leftValueX, cardY5 + 34);

                // Reset to the desired default font settings if needed
                pdf.setFont(fontSettings.font, 'normal');

                ////////////////////////////////////////////////////////////////////////////////////////////////////

                // Bold "Vision With Glass"
                pdf.setFont('Roboto', 'bold');
                pdf.text(`Vision Without Glass:`, psychoLabelX, cardY5 + 45);

                // N Right
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`N Right:`, psychoLabelX, cardY5 + 52);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.re_near_without_glasses || 'N/A'}`, psychoValueX, cardY5 + 52); // Adjust X-coordinate for the value

                // N Left
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`N Left:`, leftLabelX, cardY5 + 52);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.le_near_without_glasses || 'N/A'}`, leftValueX, cardY5 + 52);

                // F Right
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`F Right:`, psychoLabelX, cardY5 + 59);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.re_far_without_glasses || 'N/A'}`, psychoValueX, cardY5 + 59); // Adjust X-coordinate for the value

                // F Left
                pdf.setFont('Roboto', 'normal'); // Set font to normal for label
                pdf.text(`F Left:`, leftLabelX, cardY5 + 59);
                pdf.setFont('Roboto', 'bold'); // Set font to bold for fetched data
                pdf.text(`${visionInfo?.le_far_without_glasses || 'N/A'}`, leftValueX, cardY5 + 59);

                // Reset to the desired default font settings if needed
                pdf.setFont(fontSettings.font, 'normal');
            }

            pdf.addPage();
            const newImgWidth = 210;
            const newImgHeight = 297;

            ////////////////////// Add the second image to the new page
            pdf.addImage(secondImage, 'PNG', 0, 0, newImgWidth, newImgHeight);
            //////////// new card VISION
            const marginRightNewCard = -180;
            const marginTopNewCard = -198;

            // Set separate width and height for the new card
            const newCardWidth = 85;
            const newCardHeight = 125;

            const newCardX = cardX5 + cardWidth + marginRightNewCard;
            const newCardY = cardY5 + marginTopNewCard;

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(newCardX, newCardY, newCardWidth, newCardHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text('DENTAL', newCardX + 5, newCardY + 7);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.setTextColor(0, 0, 0);

            const newCardLabelX = newCardX + 5;
            const newCardValueX = newCardX + 50;
            const marginTopCard66 = -136; // Adjust the margin-top for the sixth card as needed
            const marginRightCard66 = -190; // Adjust the margin-right for the sixth card as needed

            // Add your specific fields for the sixth card
            pdf.text(`Oral Hygiene:`, newCardLabelX, newCardY + 20);
            pdf.setFont('Roboto', 'bold');
            // pdf.text(`${dentalInfo?.oral_hygiene || 'N/A'}`, card7ValueX, cardY7 + marginTopCard7 + 20);
            pdf.text(`${dentalInfo?.oral_hygiene === "1" ? 'Good' : dentalInfo?.oral_hygiene === "2" ? 'Fair' : dentalInfo?.oral_hygiene === '3' ? 'Poor' : 'N/A'}`, newCardValueX, newCardY + 20);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Gum Condition:`, newCardLabelX, newCardY + 27);
            pdf.setFont('Roboto', 'bold');
            // pdf.text(`${dentalInfo?.gum_condition || 'N/A'}`, card7ValueX, cardY7 + marginTopCard7 + 27);
            pdf.text(`${dentalInfo?.gum_condition === "1" ? 'Good' : dentalInfo?.gum_condition === "2" ? 'Fair' : dentalInfo?.gum_condition === "3" ? 'Poor' : 'N/A'}`, newCardValueX, newCardY + 27);
            pdf.setFont(fontSettings.font, 'normal');

            // pdf.text(`Oral Hygiene:`, card7LabelX, cardY7 + marginTopCard7 + 34);
            // pdf.setFont('Roboto', 'bold');
            // pdf.text(`${dentalInfo?.oral_hygiene || 'N/A'}`, card7ValueX, cardY7 + marginTopCard7 + 34);
            // pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Oral Ulcer:`, newCardLabelX, newCardY + 34);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.oral_ulcers === "1" ? 'No' : dentalInfo?.oral_ulcers === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 34);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Gum Bleeding:`, newCardLabelX, newCardY + 41);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.gum_bleeding === "1" ? 'No' : dentalInfo?.gum_bleeding === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 41);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Discoloration of teeth:`, newCardLabelX, newCardY + 48);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.discoloration_of_teeth === '1' ? 'No' : dentalInfo?.discoloration_of_teeth === '2' ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 48);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Food Impaction:`, newCardLabelX, newCardY + 55);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.food_impaction === "1" ? 'No' : dentalInfo?.food_impaction === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 55);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Carious Teeth:`, newCardLabelX, newCardY + 62);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.carious_teeth === "1" ? 'No' : dentalInfo?.carious_teeth === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 62);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Extraction Done:`, newCardLabelX, newCardY + 69);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.extraction_done === '1' ? 'No' : dentalInfo?.extraction_done === '2' ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 69);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Tooth Brushing:`, newCardLabelX, newCardY + 76);
            pdf.setFont('Roboto', 'bold');
            // pdf.text(`${dentalInfo?.tooth_brushing_frequency || 'N/A'}`, card7ValueX, cardY7 + marginTopCard7 + 83);
            pdf.text(`${dentalInfo?.tooth_brushing_frequency === "1" ? 'Less Than 1 Day' : dentalInfo?.tooth_brushing_frequency === '2' ? '2/day' : dentalInfo?.tooth_brushing_frequency === "3" ? '1/day' : 'N/A'}`, newCardValueX, newCardY + 76);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Reffered to Specialist:`, newCardLabelX, newCardY + 83);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.reffered_to_specialist === "1" ? 'No' : dentalInfo?.reffered_to_specialist === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 83);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Sensitive Teeth:`, newCardLabelX, newCardY + 90);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.sensitive_teeth === "1" ? 'No' : dentalInfo?.sensitive_teeth === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 90);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Malalignment:`, newCardLabelX, newCardY + 97);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.malalignment === '1' ? 'No' : dentalInfo?.malalignment === '2' ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 97);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Orthodontic Treatment:`, newCardLabelX, newCardY + 104);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.orthodontic_treatment === "1" ? 'No' : dentalInfo?.orthodontic_treatment === "2" ? 'Yes' : 'N/A'}`, newCardValueX, newCardY + 104);
            pdf.setFont(fontSettings.font, 'normal');

            pdf.text(`Treatment Given:`, newCardLabelX, newCardY + 111);
            pdf.setFont('Roboto', 'bold');
            pdf.text(`${dentalInfo?.treatment_given || 'N/A'}`, newCardValueX, newCardY + 111);
            pdf.setFont(fontSettings.font, 'normal');

            //////////////////////////// Fifth Card (Immunisation)
            const cardWidth6 = 130; // Adjust the width as needed

            const cardX6 = cardX5 + cardWidth + 10;
            const cardY6 = cardY5 + cardSeparateHeight;
            const card6Height = 80; // Adjust the height as needed
            const marginTopCard6 = -136; // Adjust the margin-top for the sixth card as needed
            const marginRightCard6 = -190; // Adjust the margin-right for the sixth card as needed

            if (SourceUrlId === '1' && immunisationInfo && immunisationInfo.name_of_vaccine.length > 0) {

                pdf.setFillColor(244, 245, 250); // #F4F5FA
                pdf.roundedRect(cardX6 + marginRightCard6, cardY6 + marginTopCard6, cardWidth6, card6Height, borderRadius, borderRadius, 'F');

                pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
                pdf.setFont(fontSettings.font, 'bold');
                pdf.text('IMMUNISATION', cardX6 + marginRightCard6 + 5, cardY6 + marginTopCard6 + 7);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.setTextColor(0, 0, 0);

                // Iterate over each vaccine entry and add it to the PDF
                immunisationInfo.name_of_vaccine.forEach((vaccine, index) => {
                    const card6LabelX = cardX6 + marginRightCard6 + 5;
                    const card6ValueX = cardX6 + marginRightCard6 + 50;
                    const card6EntryHeight = 7; // Adjust the entry height as needed

                    // Calculate the Y position for the current vaccine entry
                    const cardY6Entry = cardY6 + marginTopCard6 + 20 + index * card6EntryHeight;

                    // Add serial number
                    pdf.text(`Serial No: ${index + 1}`, card6LabelX, cardY6Entry);
                    pdf.setFont(fontSettings.font, 'normal');

                    // Add your specific fields for each vaccine entry
                    pdf.text(`Name of Vaccine:`, card6LabelX, cardY6Entry + card6EntryHeight);
                    pdf.setFont('Roboto', 'bold');
                    pdf.text(`${vaccine?.immunisations || 'N/A'}`, card6ValueX, cardY6Entry + card6EntryHeight);
                    pdf.setFont(fontSettings.font, 'normal');

                    pdf.text(`Scheduled Date From:`, card6LabelX, cardY6Entry + 2 * card6EntryHeight);
                    pdf.setFont('Roboto', 'bold');
                    pdf.text(`${vaccine?.scheduled_date_from || 'N/A'}`, card6ValueX, cardY6Entry + 2 * card6EntryHeight);
                    pdf.setFont(fontSettings.font, 'normal');
                });
            }
            // else {
            //     //////// fitness certificate
            //     pdf.addPage();
            //     pdf.addImage(secondImage, 'PNG', 0, 0, newImgWidth, newImgHeight);
            //     const cardWidth6 = 190; // Adjust the width as needed

            //     const cardX6 = cardX5 + cardWidth + 10;
            //     const cardY6 = cardY5 + cardSeparateHeight;
            //     const card6Height = 80; // Adjust the height as needed
            //     const marginTopCard6 = -270; // Adjust the margin-top for the sixth card as needed
            //     const marginRightCard6 = -190; // Adjust the margin-right for the sixth card as needed
            //     const textMargin = 12; // 1em margin (assuming font size of 12pt)
            //     const lineGap = 3; // Adjust the gap between lines as needed
            //     const overallMarginTop = 10; // Adjust the margin-top for the overall text

            //     pdf.setFillColor(255, 255, 255);
            //     pdf.roundedRect(cardX6 + marginRightCard6, cardY6 + marginTopCard6, cardWidth6, card6Height, borderRadius, borderRadius, 'F');

            //     pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            //     pdf.setFont(fontSettings.font, 'bold');

            //     // Center-align and increase font size for "FITNESS CERTIFICATE"
            //     const headerFontSize = 16; // Increase the font size
            //     pdf.setFontSize(headerFontSize);
            //     const headerText = 'FITNESS CERTIFICATE';
            //     const textWidth = pdf.getTextWidth(headerText);
            //     const centerX = cardX6 + marginRightCard6 + (cardWidth6 / 2) - (textWidth / 2);
            //     const headerY = cardY6 + marginTopCard6 + 15; // Adjusted Y coordinate with margin-top
            //     pdf.text(headerText, centerX, headerY);

            //     pdf.setFont(fontSettings.font, 'normal');
            //     pdf.setFontSize(12); // Reset font size to normal

            //     const card6LabelX = cardX6 + marginRightCard6 + 5;
            //     const card6EntryHeight = 7; // Adjust the entry height as needed

            //     // Calculate the Y position for the current certificate entry
            //     let certificateText = `This is to certify that ${basicInfo.prefix}. ${basicInfo.name}, Age ${basicInfo.year} yrs, Employee No. ${basicInfo.employee_id} has undergone the medical examination on 15/04/2024. Based on examination and Investigation results He / She is free from infection / contagious disease, and is medically fit to continue duties.`;
            //     let currentY = headerY + headerFontSize + overallMarginTop; // Add margin-top to the header text

            //     const lines = pdf.splitTextToSize(certificateText, cardWidth6 - textMargin * 2); // Adjust for text margin
            //     const lineHeight = pdf.getTextDimensions('text', { fontSize: 12 }).h;

            //     lines.forEach(line => {
            //         if (currentY + lineHeight <= cardY6 + marginTopCard6 + card6Height - lineHeight) {
            //             pdf.text(line, card6LabelX + textMargin, currentY);
            //             currentY += lineHeight + lineGap; // Increment Y position for the next line with gap
            //         }
            //     });

            //     // Add Signature Image
            //     // const signatureImageX = cardX6 + marginRightCard6 + cardWidth6 - textMargin - 50; // Shifted further to the right
            //     // const signatureImageY = cardY6 + marginTopCard6 + card6Height - 20; // Adjust for the bottom and image height
            //     // const signatureImageWidth = 50; // Adjust image width as needed
            //     // const signatureImageHeight = 20; // Adjust image height as needed

            //     // pdf.addImage(SignLogo, signatureImageX, signatureImageY, signatureImageWidth, signatureImageHeight);
            // }

            // else {
            //     //////// fitnesss certificate
            //     const cardWidth6 = 190; // Adjust the width as needed

            //     const cardX6 = cardX5 + cardWidth + 10;
            //     const cardY6 = cardY5 + cardSeparateHeight;
            //     const card6Height = 80; // Adjust the height as needed
            //     const marginTopCard6 = -136; // Adjust the margin-top for the sixth card as needed
            //     const marginRightCard6 = -190; // Adjust the margin-right for the sixth card as needed

            //     pdf.setFillColor(244, 245, 250);
            //     pdf.roundedRect(cardX6 + marginRightCard6, cardY6 + marginTopCard6, cardWidth6, card6Height, borderRadius, borderRadius, 'F');

            //     pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            //     pdf.setFont(fontSettings.font, 'bold');
            //     pdf.text('FITNESS CERTIFICATE', cardX6 + marginRightCard6 + 5, cardY6 + marginTopCard6 + 7);
            //     pdf.setFont(fontSettings.font, 'normal');

            //     const card6LabelX = cardX6 + marginRightCard6 + 5;
            //     const card6EntryHeight = 7; // Adjust the entry height as needed

            //     // Calculate the Y position for the current vaccine entry
            //     const cardY6Entry = cardY6 + marginTopCard6 + 2 + card6EntryHeight;

            //     // Add your specific fields for each vaccine entry
            //     pdf.text(`This is To certify that ${basicInfo.name} has Undergone the medical examination with us`, card6LabelX, cardY6Entry + card6EntryHeight + 2);

            //     // Add your specific fields for each vaccine entry
            //     pdf.text(`based on examination and investigation results is free from infection disease for`, card6LabelX, cardY6Entry + card6EntryHeight + 8);

            //     // Add your specific fields for each vaccine entry
            //     pdf.text(`which diagnostic tests have been carried out and is medically fit to continue duties.`, card6LabelX, cardY6Entry + card6EntryHeight + 14);
            // }

            // Sixth Card (Dental) visionnnnnnnnn VISION
            const cardX7 = cardX6 + cardWidth + 10;
            const cardY7 = cardY6; // Adjust the margin-top as needed
            const card7Height = 125; // Adjust the height as needed
            const marginTopCard7 = -268; // Adjust the margin-top for the sixth card as needed
            const marginRightCard7 = -190; // Adjust the margin-right for the sixth card as needed

            if (SourceUrlId === '1') {
                pdf.setFillColor(244, 245, 250); // #F4F5FA
                pdf.roundedRect(cardX7 + marginRightCard7, cardY7 + marginTopCard7, cardWidth, card7Height, borderRadius, borderRadius, 'F');

                pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
                pdf.setFont(fontSettings.font, 'bold');
                pdf.text('PSYCHOLOGICAL SCREENING', cardX7 + marginRightCard7 + 5, cardY7 + marginTopCard7 + 7);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.setTextColor(0, 0, 0);

                const card7LabelX = cardX7 + marginRightCard7 + 5;
                const card7ValueX = cardX7 + marginRightCard7 + 50;

                // Include your specific fields from the psycho_info object
                pdf.text(`Difficulty In Reading:`, card7LabelX, cardY7 + marginTopCard7 + 20);
                pdf.setFont('Roboto', 'bold');
                // pdf.text(`${psychoInfo?.diff_in_read || 'N/A'}`, psychoValueX, cardY5 + 20);
                pdf.text(`${psychoInfo?.diff_in_read !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 20);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Difficulty In Writing:`, card7LabelX, cardY7 + marginTopCard7 + 27);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.diff_in_write !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 27);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Hyper Reactive:`, card7LabelX, cardY7 + marginTopCard7 + 34);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.hyper_reactive !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 34);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Aggresive:`, card7LabelX, cardY7 + marginTopCard7 + 40);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.aggresive !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 40);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Urine Tool:`, card7LabelX, cardY7 + marginTopCard7 + 47);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.urine_stool !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 47);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Peers:`, card7LabelX, cardY7 + marginTopCard7 + 54);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.peers !== 1 ? 'No' : 'Yes'}`, card7ValueX, cardY7 + marginTopCard7 + 54);
                pdf.setFont(fontSettings.font, 'normal');

                pdf.text(`Psycho Condition:`, card7LabelX, cardY7 + marginTopCard7 + 61);
                pdf.setFont('Roboto', 'bold');
                pdf.text(`${psychoInfo?.pycho_conditions || 'N/A'}`, card7ValueX, cardY7 + marginTopCard7 + 61);
                pdf.setFont(fontSettings.font, 'normal');
            }
            else {
            }
            // Save the PDF
            const pdfFileName = `healthcard_${selectedCitizenId}.pdf`;

            pdf.save(pdfFileName);
        } catch (error) {
            console.error('Error fetching additional data', error);
        }
    };

    const handleFitness = async () => {
        if (!scheduleData || !selectedScheduleCount) {
            console.error('No data available for download or no schedule count selected');
            return;
        }

        try {
            const response = await fetch(`${Port}/Screening/citizen-download/${selectedCitizenId}/${selectedScheduleCount}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            console.log(response);

            const additionalData = await response.json();
            const bmiInfo = additionalData.bmi_info?.[0]; // Assuming bmi_info is present in the response
            const basicInfo = additionalData.basic_info?.[0]; // Assuming basic_info is present in the response

            // Set font family and size
            const pdf = new jsPDF();
            const fontSettings = {
                font: 'Roboto',
                fontSize: 12,
            };
            const imgWidth = 210; // Adjust as needed
            const imgHeight = 297; // Adjust as needed

            pdf.addImage(backgroundImage, 'PNG', 0, 0, imgWidth, imgHeight);

            // Header
            const leftMargin = 30; // Adjust the left margin as needed

            const headerText = 'FITNESS CERTIFICATE';
            const headerX = imgWidth / 1.7 + leftMargin;
            const headerY = 15;

            // Check if citizenInfo is available before attempting to use it

            if (imageShows) {
                const cmToPoints = 15; // Conversion factor from cm to points

                const imageWidthCm = 1.55; // Set the width of the image in cm
                const imageHeightCm = 1.7; // Set the height of the image in cm
                const borderRadiusCm = 1.5; // Set the border radius of the image in cm

                const imageWidth = imageWidthCm * cmToPoints; // Convert width from cm to points
                const imageHeight = imageHeightCm * cmToPoints; // Convert height from cm to points
                const borderRadius = borderRadiusCm * cmToPoints; // Convert border radius from cm to points

                pdf.addImage(imageShows, 'JPEG', 20, 15, imageWidth, imageHeight, borderRadius); // Adjust the coordinates, dimensions, and border radius as needed
            } else {
                // Show the default image if imageShows is not available
                const cmToPoints = 15; // Conversion factor from cm to points

                const defaultImageWidthCm = 1.5; // Set the width of the default image in cm
                const defaultImageHeightCm = 1.5; // Set the height of the default image in cm

                const defaultImageWidth = defaultImageWidthCm * cmToPoints; // Convert width from cm to points
                const defaultImageHeight = defaultImageHeightCm * cmToPoints; // Convert height from cm to points

                pdf.addImage(defaultImage, 'WEBP', 20, 19, defaultImageWidth, defaultImageHeight); // Adjust the coordinates and dimensions as needed
            }

            const { name, year: age, added_date: currentDate } = basicInfo;

            if (basicInfo) {
                const citizenInfoFontSize = 10;

                // Set text color to white
                pdf.setTextColor(255, 255, 255);

                // Display Citizen Name
                const nameText = `${basicInfo.name}`;
                pdf.text(nameText, 45, 28);
                pdf.setFontSize(citizenInfoFontSize);

                // Display Gender
                const genderText = `${basicInfo.gender}`;
                pdf.text(genderText, 45, 34);
                pdf.setFontSize(citizenInfoFontSize);

                // Display Age
                const ageText = ` ${basicInfo.year} years`;
                pdf.text(ageText, 43.5, 39);
                pdf.setFontSize(citizenInfoFontSize);

                // Set text color back to black (or any other desired color)
                pdf.setTextColor(0, 0, 0);

                // Apply bottom margin for all displayed text
                const bottomMargin = 10;
                pdf.text('', 2, pdf.internal.pageSize.height - bottomMargin);

                // Display citizen_id
                pdf.setTextColor(255, 255, 255); // Set text color to white
                const citizenIdText = `Citizen ID: ${basicInfo.citizen_id}`;
                pdf.text(citizenIdText, imgWidth - 20, 37, { align: 'right' });
                pdf.setFontSize(citizenInfoFontSize);

                // Display Schedule Count
                const scheduleCountText = `Schedule ID : ${basicInfo.schedule_id}`;
                pdf.text(scheduleCountText, imgWidth - 20, 31, { align: 'right' });
                pdf.setFontSize(citizenInfoFontSize);

                // Display Schedule Count
                const shceduleID = `Screening : ${basicInfo.schedule_count}`;
                pdf.text(shceduleID, imgWidth - 20, 25, { align: 'right' });
                pdf.setFontSize(citizenInfoFontSize);

                const currentTime = getCurrentTime();

                // // Display current time
                // pdf.setTextColor(0, 0, 0); // Set text color back to black
                // const downloadTimeText = `Download Time: ${currentTime}`;
                // pdf.text(downloadTimeText, 20, pdf.internal.pageSize.height - 2);
            }

            pdf.setTextColor(255, 255, 255); // Set text color to white for the header
            pdf.setFontSize(25); // Adjust the font size directly

            pdf.text(headerText, headerX, headerY, { align: 'right' });

            // Reset font size and text color for subsequent text
            pdf.setFont(fontSettings.font, 'normal');
            pdf.setFontSize(fontSettings.fontSize);
            pdf.setTextColor(0, 0, 0); // Reset text color to black

            const cardWidth = 190;
            const bmiCardHeight = 70; // Adjust the BMI card height as needed
            const borderRadius = 4; // Border radius in millimeters

            // First Card (BMI Information)
            const cardX1 = 10;
            const marginTopBMI = 33; // Adjust the margin-top for BMI card as needed
            const cardY1 = 20 + marginTopBMI;

            pdf.setFillColor(244, 245, 250); // #F4F5FA
            pdf.roundedRect(cardX1, cardY1, cardWidth, bmiCardHeight, borderRadius, borderRadius, 'F');

            pdf.setTextColor(49, 55, 116); // RGB values corresponding to #313774
            pdf.setFont(fontSettings.font, 'bold');

            pdf.setTextColor('black'); // Set text color to black
            pdf.setFont(fontSettings.font, 'bold');
            pdf.text(`This is To certify that ${name} has Undergone the medical examination with us`, cardX1 + 5, cardY1 + 14);
            pdf.text(`${currentDate} based on examination and investigation results is free from infection`, cardX1 + 5, cardY1 + 21);
            pdf.text(`disease for which diagnostic tests have been carried out and is medically fit to continue duties.`, cardX1 + 5, cardY1 + 28);
            pdf.setFont(fontSettings.font, 'normal');
            pdf.setTextColor(0, 0, 0);

            pdf.setTextColor(0, 0, 0);
            // Save the PDF
            const pdfFileName = `fitness_${selectedCitizenId}.pdf`;

            pdf.save(pdfFileName);
        } catch (error) {
            console.error('Error fetching additional data', error);
        }
    };

    //////////// handle table psycho
    const handleEyeClick = async (citizenID) => {
        try {
            const response = await fetch(`${Port}/Screening/schedule-count/?citizen_id=${citizenID}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            const data = await response.json();
            setScheduleData(data);
            setIsDataFetched(true);

            const options = data.schedule_count_sequence ?? [];
            setTotalCount(options);
            setSelectedCitizenId(citizenID);

            // Set data for each type (vitals, bmi, immunization, etc.)
            setVitalsData(data.vitalsData);
            setBmiData(data.bmiData);
            setImmunizationData(data.immunizationData);

            setImage(data?.Citizen_info?.[0]?.photo)

            console.log('API Schedule Response:', data);
            console.log('Citizen Name:', data?.Citizen_info?.[0]?.name);
            console.log('Citizen Idddddd:', data?.Citizen_info?.[0]?.citizen_id);
            console.log('Total Count:', options);
            setNewID(data?.Citizen_info?.[0]?.citizen_id)
        } catch (error) {
            console.error('Error Fetching Data', error);
        }
    }

    useEffect(() => {
        handleEyeClick();
    }, []);

    const fetchCitizenVital = async (iddddddddddd) => {
        try {
            const res = await fetch(`${Port}/Screening/citizen-info/${newId}/${iddddddddddd}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            console.log('API RESPONSE', res);
            const data = await res.json();
            setIsDataFetched(true);

            const scheduleId = data.basic_info?.[0]?.schedule_id || null;
            console.log('Schedule ID:', scheduleId);
            setScheduleId(scheduleId);

            ///////////////////////// BMI
            const bmiInfo = data.bmi_info ? data.bmi_info.map(item => {
                return {
                    id: item.id,
                    citizenId: item.citizen_id,
                    scheduleId: item.schedule_id,
                    scheduleCount: item.schedule_count,
                    gender: item.gender,
                    dob: item.dob,
                    year: item.year,
                    months: item.months,
                    days: item.days,
                    height: item.height,
                    weight: item.weight,
                    weightForAge: item.weight_for_age,
                    heightForAge: item.height_for_age,
                    weightForHeight: item.weight_for_height,
                    bmi: item.bmi,
                    armSize: item.arm_size,
                    formSubmit: item.form_submit,
                    isDeleted: item.is_deleted,
                    addedBy: item.added_by,
                    addedDate: item.added_date,
                    modifyBy: item.modify_by,
                    modifyDate: item.modify_date,
                };
            }) : [];
            console.log('BMI Info:', bmiInfo);
            setBmiData(bmiInfo);

            ///////////////////////// Psycho
            const psychoConditions = data.psycho_info ? data.psycho_info.map(item => item.pycho_conditions) : [];
            console.log('Psycho Conditions:', psychoConditions);
            setPsychoData(psychoConditions);

            ///////////////////////// Dental
            const dentalConditions = data.dental_info ? data.dental_info.map(item => item.dental_conditions) : [];
            console.log('Dental Conditions:', dentalConditions);
            setDentalData(dentalConditions);

            ///////////////////////// Immunization
            const immunizationData = data.immunization_info ? data.immunization_info.map(item => {
                return {
                    immunizationCode: item.immunization_code,
                    vaccines: item.name_of_vaccine.map(vaccine => {
                        return {
                            immunization: vaccine.immunisations,
                            givenYesNo: vaccine.given_yes_no,
                            scheduledDateFrom: vaccine.scheduled_date_from,
                            scheduledDateTo: vaccine.scheduled_date_to,
                            windowPeriodDaysFrom: vaccine.window_period_days_from,
                            windowPeriodDaysTo: vaccine.window_period_days_to,
                        };
                    }),
                };
            }) : [];
            console.log('Immunization Data:', immunizationData);
            setImmunizationData(immunizationData);

            //////////////////////// Vital
            const vitalsConditions = data.vital_info ? data.vital_info.map(item => {
                return {
                    pulse: item.pulse,
                    pulse_conditions: item.pulse_conditions,
                    sys_mm: item.sys_mm,
                    sys_mm_conditions: item.sys_mm_conditions,
                    dys_mm: item.dys_mm,
                    dys_mm_mm_conditions: item.dys_mm_mm_conditions,
                    hb: item.hb,
                    hb_conditions: item.hb_conditions,
                    oxygen_saturation: item.oxygen_saturation,
                    oxygen_saturation_conditions: item.oxygen_saturation_conditions,
                    rr: item.rr,
                    rr_conditions: item.rr_conditions,
                    temp: item.temp,
                    temp_conditions: item.temp_conditions,
                }
            }) : [];
            console.log('Vitals Conditions:', vitalsConditions);
            setVitalsData(vitalsConditions)

            ///////////////////////// Vision
            const visionConditions = data.vision_info ? data.vision_info.map(item => {
                return {
                    vision_with_glasses: item.vision_with_glasses,
                    vision_without_glasses: item.vision_without_glasses,
                    color_blindness: item.color_blindness,
                };
            }) : [];
            console.log('Vision Conditions:', visionConditions);
            setVisionData(visionConditions);

            ///////////////////////// Auditory
            const auditoryCheckboxData = data.audit_info ? data.audit_info.map(item => item.checkboxes) : [];
            console.log('Auditory Checkbox Data:', auditoryCheckboxData);
            setAuditoryData(auditoryCheckboxData);

            ///////////////////////// Basic Screening

            const basiScreenData = data.basic_info ? data.basic_info.map(item => item.birth_defects) : [];
            console.log('Basic Screen Data:', basiScreenData);
            setBasicScreenData(basiScreenData);

            setStore(data);
        } catch (error) {
            console.error("Error fetching Source Name against Tehsil data:", error);
        }
    };

    useEffect(() => {
        fetchCitizenVital();
    }, []);

    const isGenderAvailable = scheduleData && scheduleData?.Citizen_info?.[0]?.gender;

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState(searchResults);

    useEffect(() => {
        const filtered = searchResults.filter(result =>
            (result.name && result.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (result.aadhar_id && result.aadhar_id.includes(searchQuery))
        );
        setFilteredResults(filtered);
    }, [searchQuery, searchResults]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const slicedResults = filteredResults.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <div>
            <div class="content-wrapper">
                <div class="content-header fullscreen">
                    <div class="container-fluid">
                        <div className="card healthcard">
                            <div className="row">
                                <div className="col-md-12">
                                    <h6 className="healthcardtitle">Healthcard Information List</h6>

                                    <div className="dropdownall mb-3">
                                        <Box>
                                            <div class="container text-center">
                                                <div class="row" style={{ display: 'flex' }}>
                                                    <div class="col" style={{ color: 'white' }}>
                                                        <TextField
                                                            select
                                                            className="healthcardlistdropdown"
                                                            size="small"
                                                            label="Source"
                                                            id="select-small"
                                                            variant="outlined"
                                                            value={selectedSource}
                                                            onChange={event => setSelectedSource(event.target.value)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontWeight: '100',
                                                                    fontSize: '14px', // Set the desired font size for the label
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Source</MenuItem>
                                                            {sourceNav.map(drop => (
                                                                <MenuItem key={drop.source_pk_id} value={drop.source_pk_id}>
                                                                    {drop.source}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                    </div>

                                                    <div class="col" style={{ color: 'white' }}>
                                                        <TextField
                                                            select
                                                            className="healthcardlistdropdown"
                                                            size="small"
                                                            label="Source State"
                                                            id="select-small"
                                                            variant="outlined"
                                                            value={selectedStateNav}
                                                            onChange={event => setSelectedStateNav(event.target.value)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontWeight: '100',
                                                                    fontSize: '14px', // Set the desired font size for the label
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Source State</MenuItem>
                                                            {sourceStateNav.map(drop => (
                                                                <MenuItem key={drop.source_state} value={drop.source_state}>
                                                                    {drop.state_name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                    <div class="col" style={{ color: 'white' }}>
                                                        <TextField
                                                            select
                                                            className="healthcardlistdropdown"
                                                            size="small"
                                                            label="Source District"
                                                            id="select-small"
                                                            variant="outlined"
                                                            value={selectedDistrictNav}
                                                            onChange={event => setSelectedDistrictNav(event.target.value)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontWeight: '100',
                                                                    fontSize: '14px', // Set the desired font size for the label
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Source District</MenuItem>
                                                            {sourceDistrictNav.map(drop => (
                                                                <MenuItem key={drop.source_district} value={drop.source_district}>
                                                                    {drop.dist_name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                    <div class="col" style={{ color: 'white' }}>
                                                        <TextField
                                                            select
                                                            className="healthcardlistdropdown"
                                                            size="small"
                                                            label="Source Tehsil"
                                                            id="select-small"
                                                            variant="outlined"
                                                            value={selectedTehsilNav}
                                                            onChange={event => setSelectedTehsilNav(event.target.value)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontWeight: '100',
                                                                    fontSize: '14px', // Set the desired font size for the label
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Source Tehsil</MenuItem>
                                                            {sourceTehsilNav.map(drop => (
                                                                <MenuItem key={drop.source_taluka} value={drop.source_taluka}>
                                                                    {drop.tahsil_name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                    <div class="col" style={{ color: 'white' }}>
                                                        <TextField
                                                            select
                                                            className="healthcardlistdropdown"
                                                            size="small"
                                                            label="Source Name"
                                                            id="select-small"
                                                            variant="outlined"
                                                            value={selectedName}
                                                            onChange={event => setSelectedName(event.target.value)}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontWeight: '100',
                                                                    fontSize: '14px', // Set the desired font size for the label
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="">Select Source Name</MenuItem>
                                                            {sourceName.map(drop => (
                                                                <MenuItem key={drop.source_pk_id} value={drop.source_pk_id}>
                                                                    {drop.source_names}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </div>

                                                    <div className='col'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-sm searchhealth'
                                                            onClick={handlesubmit} // Call the handleSearch function when the button is clicked
                                                        >
                                                            Search
                                                        </button>
                                                    </div>

                                                    {/* <div className='col'>
                                                        <input className="form-control searchhealthid"
                                                            placeholder='Search Citizen'
                                                        />
                                                        <SearchIcon className="searchiconhealthid" />
                                                    </div> */}

                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    className='form-control mb-3'
                                    placeholder="Search Citizen..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <table className="table table-borderless">
                                    <thead className="">
                                        <tr className="card cardheadhealthcard">
                                            <th className="col">Citizen Name</th>
                                            <th className="col">Aadhar ID</th>
                                            <th className="col">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    <CircularProgress className='circular-progress-containerscreeninglist' style={{ margin: 'auto' }} />
                                                </td>
                                            </tr>
                                        ) : filteredResults.length === 0 ? (
                                            <tr>
                                                <td className="nodatafounddddd" colSpan="3">No data found</td>
                                            </tr>
                                        ) : (
                                            slicedResults.map((result, index) => (
                                                <tr
                                                    key={index}
                                                    className={`card cardbodyhealthcard ${result.citizen_id === 'selectedCitizenId' ? 'hovered' : ''}`}
                                                    onClick={() => handleEyeClick(result.citizen_id)}
                                                >
                                                    <td className="col">{result.name.toLowerCase().charAt(0).toUpperCase() + result.name.toLowerCase().slice(1)}</td>
                                                    <td className="col">{result.aadhar_id}</td>
                                                    <td className="col">
                                                        <RemoveRedEyeOutlinedIcon />
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="paginationnew" style={{ marginTop: '0%' }}>
                                                    <TablePagination
                                                        component="div"
                                                        count={filteredResults.length}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        rowsPerPageOptions={[5, 10, 20]} // Adjust options based on your requirements
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div className="col-md-8">
                                <div className={`card ${isDataFetched ? 'data-fetched' : ''}`}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-4 titlehealth">Citizen Name : </div>
                                                <div className="col-md-7 healthresponsetitle">{scheduleData.Citizen_info?.[0]?.name}</div>

                                                <div className="col-md-4 titlehealth1">Citizen ID : </div>
                                                <div className="col-md-7 healthresponsetitle1">{scheduleData.Citizen_info?.[0]?.citizen_id}</div>
                                            </div>
                                            <hr className="hrline" />
                                            <div className="row cardhealth">
                                                <div className='col-md-3 ealthcardtitle'>Gender</div>
                                                <div className='col-md-4 ealthcardtitle'>DOB</div>
                                                <div className='col-md-4 ealthcardtitle'>Age</div>

                                                <div className='col-md-3 ealthcardtitle1'>{scheduleData.Citizen_info?.[0]?.gender}</div>
                                                <div className='col-md-4 ealthcardtitle1'>{scheduleData.Citizen_info?.[0]?.dob}</div>
                                                <div className='col-md-4 ealthcardtitle1'>{scheduleData.Citizen_info?.[0]?.year}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <label className="visually-hidden screenid">Screening Count</label>
                                                    <select className="form-control from-control-sm screedropdown"
                                                        onChange={(e) => {
                                                            fetchCitizenVital(e.target.value);
                                                            setSelectedScheduleCount(e.target.value);
                                                        }}>
                                                        <option>Select</option>
                                                        {totalCount.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-4">
                                                    <button className="downloadhealthcardddddddddddddddddddddddddddd"
                                                        onClick={handleDownloadClick}

                                                    >
                                                        Healthcard
                                                    </button>

                                                    {/* <button className="fitness"
                                                        onClick={handleFitness}

                                                    >
                                                        Fitness
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 idddddddd">Schedule ID-</div>
                                                <div className="col-md-6 scheduledidhealthcard" style={{ marginTop: '3px', marginLeft: '-15px' }}>{scheduleId}</div>

                                                {/* <div className="col-md-6 idddddddd">Schedule From/To Date-</div>
                                                <div className="col-md-6"></div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="row form-tabs">
                                                <div className={`col healthvitalname ${selectedForm === 'bmiform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('bmiform')}>BMI & Symptoms</div>
                                                <div className={`col-md-1 healthvitalname ${selectedForm === 'vitalform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('vitalform')}>Vital</div>
                                                <div className={`col healthvitalname ${selectedForm === 'basicscreenform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('basicscreenform')}>Basic Screening</div>
                                                <div className={`col healthvitalname ${selectedForm === 'immunizationform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('immunizationform')}>Immunization</div>
                                                <div className={`col-md-1 healthvitalname ${selectedForm === 'auditoryform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('auditoryform')}>Auditory</div>
                                                <div className={`col healthvitalname ${selectedForm === 'dentalcheckupform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('dentalcheckupform')}>Dental Check Up</div>
                                                <div className={`col-md-1 healthvitalname ${selectedForm === 'visionform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('visionform')}>Vision</div>
                                                <div className={`col healthvitalname ${selectedForm === 'psychologicalform' ? 'selected-tab' : ''}`} onClick={() => handleFormClick('psychologicalform')}>Psychological</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* <div className="col-md-4">
                                        <img className="malepiccc" src={malepic} />
                                        <div className="clickable-point-1" onClick={() => handlePointClick('vitalform')}></div>
                                        <div className="clickable-point-2" onClick={() => handlePointClick('basicscreenform')}></div>
                                        <div className="clickable-point-3" onClick={() => handlePointClick('bmiform')}></div>
                                        <div className="clickable-point-4" onClick={() => handlePointClick('immunizationform')}></div>
                                        <div className="clickable-point-5" onClick={() => handlePointClick('auditoryform')}></div>
                                        <div className="clickable-point-6" onClick={() => handlePointClick('dentalcheckupform')}></div>
                                        <div className="clickable-point-7" onClick={() => handlePointClick('visionform')}></div>
                                        <div className="clickable-point-8" onClick={() => handlePointClick('psychologicalform')}></div>
                                    </div> */}

                                    <div className="col-md-4">
                                        {isGenderAvailable && (
                                            <>
                                                {scheduleData.Citizen_info[0].gender === 'Male' ? (
                                                    <img className="malepiccc" src={malepic} alt="Male" />
                                                ) : (
                                                    <img className="femalepiccc" src={femalePic} alt="Female" />
                                                )}
                                                {/* <div className="clickable-point-1" onClick={() => handlePointClick('vitalform')}></div>
                                                <div className="clickable-point-2" onClick={() => handlePointClick('basicscreenform')}></div>
                                                <div className="clickable-point-3" onClick={() => handlePointClick('bmiform')}></div>
                                                <div className="clickable-point-4" onClick={() => handlePointClick('immunizationform')}></div>
                                                <div className="clickable-point-5" onClick={() => handlePointClick('auditoryform')}></div>
                                                <div className="clickable-point-6" onClick={() => handlePointClick('dentalcheckupform')}></div>
                                                <div className="clickable-point-7" onClick={() => handlePointClick('visionform')}></div>
                                                <div className="clickable-point-8" onClick={() => handlePointClick('psychologicalform')}></div> */}
                                            </>
                                        )}
                                    </div>

                                    <div className="col-md-8">
                                        <div className="card cardhealthcardallvitalscomponenet">
                                            {openForm === 'vitalform' && <Vitalhealth vitalsData={vitalsData} />}
                                            {openForm === 'basicscreenform' && <BasicScreenVital basicScreenData={basicScreenData} />}
                                            {openForm === 'bmiform' && <BmiHealth bmiData={bmiData} />}
                                            {openForm === 'immunizationform' && <ImmunisationHealth immunizationData={immunizationData} />}
                                            {openForm === 'auditoryform' && <AuditoryHealth auditoryData={auditoryData} />}
                                            {openForm === 'dentalcheckupform' && <DentalHealth dentalData={dentalData} />}
                                            {openForm === 'visionform' && <VisionHealth visionData={visionData} />}
                                            {openForm === 'psychologicalform' && <PsychologicalHealth psychoData={psychoData} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div>
                            {imageShows && <img src={imageShows} alt="Fetched Image" style={{ height: "0px", width: "0px" }} />}
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthList
